import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import classNames from './SpeechToTextButton.module.scss';
import classes from 'classnames';

export enum SpeechLang {
  English = 'en-US',
  Spanish = 'es-ES',
  Hebrew = 'he',
}

interface SpeechToTextButton {
  lang?: SpeechLang;
  className?: string;
  onText: (text: string) => void;
  onStart: () => void;
  onEnd: () => void;
};

const SpeechToTextButton = forwardRef(({ lang = SpeechLang.English, className, onText, onEnd, onStart }: SpeechToTextButton, ref) => {
  const isActive = useRef(false);
  const [ isRecording, setIsRecording ] = useState(false);
  const preText = useRef('');
  const text = useRef('');
  const pause = useRef(false);
  const resetTimeout = useRef<any>();
  const recognitionRef = useRef<any>(new (
    (window as any).SpeechRecognition || 
    (window as any).webkitSpeechRecognition || 
    (window as any).mozSpeechRecognition || 
    (window as any).msSpeechRecognition
  )());

  useEffect(() => {
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.maxAlternatives = 2;

    isActive.current = true;
    return () => {
      isActive.current = false;
      if (resetTimeout.current) {
        clearTimeout(resetTimeout.current);
      }
    };
  }, []);

  const reset = useCallback(() => {
    if (isRecording && recognitionRef.current) {
      try {
        recognitionRef.current.stop();
      }
      catch {}
    }
  }, [isRecording]);

  useEffect(() => {
    recognitionRef.current.onresult = (event: any) => {
      if(isActive.current && !pause.current) {
        let transcripts = [preText.current];
        for (let i = 0; i < event.results.length; ++i) {
          const lastOptionIndex = event.results[i].length - 1;
          transcripts.push(event.results[i][lastOptionIndex].transcript.trim());
        }
        text.current = transcripts.join(' ').trim();
        onText(text.current);

        if (resetTimeout.current) {
          clearTimeout(resetTimeout.current);
        }
        resetTimeout.current = setTimeout(() => {
          if (isActive.current) {
            reset();
          }
        }, 1000 * 5);
      }
    };
  }, [onText, reset]);

  useEffect(() => {
    recognitionRef.current.onstart = () => {
      if(isActive.current && !pause.current) {
        onStart();
      }
    };
  }, [onStart]);

  useEffect(() => {
    recognitionRef.current.onend = () => {
      if(isActive.current && !pause.current) {
        onEnd();
        if (isRecording) {
          text.current = '';
          preText.current = '';
          try {
            recognitionRef.current.start();
          }
          catch {}
        }
      }
    };
  }, [onEnd, isRecording]);

  useEffect(() => {
    recognitionRef.current.lang = lang;
  }, [lang]);

  useImperativeHandle(ref, () => {
    return {
        reset: reset,
        pause: (isPause: boolean) => {
          pause.current = isPause;
          if (isPause) {
            preText.current = [preText.current, text.current].join(' ').trim();
            text.current = '';
          }
          if (isRecording) {
            if (isPause) {
              try {
                recognitionRef.current.stop();
              }
              catch {}
            }
            else {
              try {
                recognitionRef.current.start();
              }
              catch {}
            }
          }
        },
    };
  }, [isRecording, reset]);

  const onClickHandle = useCallback((event: any) => {
    event.preventDefault();

    if (isRecording) {
      setIsRecording(false);
      try {
        recognitionRef.current.stop();
      }
      catch {}
    }
    else {
      setIsRecording(true);
      try {
        recognitionRef.current.start();
      }
      catch {}
    }
    text.current = '';
    preText.current = '';
  }, [isRecording]);

  return recognitionRef.current && <button onClick={onClickHandle} className={classes(classNames.stt_btn, className, {[classNames.recording]: isRecording})}>
    <svg viewBox='0 0 24 25'>
      <g style={{fill: 'currentColor', stroke: 'none'}} >
        <path d="M12 17C13.1935 17 14.3381 16.5259 15.182 15.682C16.0259 14.8381 16.5 13.6935 16.5 12.5V6.5C16.5 5.30653 16.0259 4.16193 15.182 3.31802C14.3381 2.47411 13.1935 2 12 2C10.8065 2 9.66193 2.47411 8.81802 3.31802C7.97411 4.16193 7.5 5.30653 7.5 6.5V12.5C7.5 13.6935 7.97411 14.8381 8.81802 15.682C9.66193 16.5259 10.8065 17 12 17Z"/>
        <path d="M18.7878 12.5092C18.5916 12.4881 18.395 12.5451 18.2406 12.6679C18.0862 12.7908 17.9864 12.9695 17.9628 13.1655C17.7981 14.6316 17.0991 15.9856 15.9992 16.9689C14.8993 17.9521 13.4757 18.4957 12.0003 18.4957C10.525 18.4957 9.10139 17.9521 8.00149 16.9689C6.90159 15.9856 6.20251 14.6316 6.03782 13.1655C6.01429 12.9695 5.9145 12.7908 5.76007 12.6679C5.60564 12.5451 5.40902 12.4881 5.21282 12.5092C5.11449 12.5185 5.01901 12.5474 4.93201 12.5942C4.845 12.6409 4.76823 12.7046 4.70621 12.7815C4.64419 12.8583 4.59817 12.9468 4.57086 13.0418C4.54355 13.1367 4.53551 13.2361 4.5472 13.3342C4.73901 15.0359 5.50952 16.62 6.72975 17.8215C7.94999 19.0229 9.54589 19.7688 11.2503 19.9342V22.2498C11.2503 22.4487 11.3293 22.6395 11.47 22.7802C11.6106 22.9208 11.8014 22.9998 12.0003 22.9998C12.1992 22.9998 12.39 22.9208 12.5307 22.7802C12.6713 22.6395 12.7503 22.4487 12.7503 22.2498V19.9342C14.4548 19.7688 16.0507 19.0229 17.2709 17.8215C18.4911 16.62 19.2616 15.0359 19.4534 13.3342C19.4651 13.2361 19.4571 13.1367 19.4298 13.0418C19.4025 12.9468 19.3565 12.8583 19.2944 12.7815C19.2324 12.7046 19.1556 12.6409 19.0686 12.5942C18.9816 12.5474 18.8862 12.5185 18.7878 12.5092Z"/>
      </g>
    </svg>
    <div className={classNames.circle}></div>
    <div className={classNames.circle}></div>
    <div className={classNames.circle}></div>
  </button>;
});

export default SpeechToTextButton;
