const env = import.meta.env;

export default {
  apiUrl: env.VITE_API_URL,
  tutorUrl: env.VITE_API_TUTOR_URL,
  apiVersion: env.VITE_API_VERSION,
  appName: env.VITE_APP_NAME,
  awsUserPoolId: env.VITE_AWS_COGNITO_USER_POOL_ID,
  awsCognitoClientId: env.VITE_AWS_COGNITO_CLIENT_ID,
  countryStateCityAPIKey: env.VITE_COUNTRY_STATE_CITY_API_KEY,
  apiChatToken: env.VITE_API_CHAT_TOKEN || "xJhgkQ8fFdM9ZsYNJ2ax92TAdoagNZ",
  apiChatSecret: env.VITE_API_CHAT_SECRET || "93ff99ff45cc8d91e0003a8e169c5f0a0a7aad8b",
  apiAvatarStrimingToken: env.VITE_API_AVATAR_STRIMING_API_TOKEN,
  starsPerQuiz: 3,
  topicsPerSection: 3,
  topicsPerSectionDesktop: 5,
};
