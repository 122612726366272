import { useCallback } from 'react';
import UseQuiz from './UseQuiz';
import { GameStatus, GameTypes, PopUpTypes } from '@/types/game';
import commonUtils from '@/utils/common';
import { Transition, TransitionStatus } from 'react-transition-group';
import classes from 'classnames';
import classNames from './QuizView.module.scss';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/store';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';
import UseScreenSize from '@/hooks/UseScreenSize';
import UseDictionary from '@/hooks/UseDictionary';
import { SkillTypes } from '@/types/skill';
import UseLocalLang from '@/hooks/UseLocalLang';

import AppModal from '@/components/AppModal';
import QuizPageControls from '@/components/QuizPage/QuizPageControls/QuizPageControls';
import QuizDetails from '@/components/QuizPage/QuizDetails/QuizDetails';
import QuizQuestion from '@/components/QuizPage/QuizQuestion/QuizQuestion';
import QuizPageIndicators from '@/components/QuizPage/QuizPageIndicators/QuizPageIndicators';
import QuizPopUp from '@/components/QuizPage/QuizPopUp/QuizPopUp';
import AppBackdrop from '@/components/AppBackdrop';
import QuizPreparation from '@/components/QuizPage/QuizPreparation/QuizPreparation';
import QuizLanguageToggler from '@/components/QuizPage/QuizLanguageToggler/QuizLanguageToggler';
import WordsList from '@/components/QuizPage/QuizPopUp/PopUpWordsList/WordsList/WordsList';
import AppLoader from '@/components/AppLoader';
import DictionaryDesktopComponents from '@/components/Dictionary/DictionaryDesktopComponents/DictionaryDesktopComponents';
import AppBanner from '@/components/AppBanner';
import LevelTestLogoutComponent from '../level-test/LevelTestLogoutComponent';
import { TopicTypes } from '@/types/topic';
import EndOfSkillTestPreparation from '@/components/QuizPage/QuizPreparation/EndOfSkillTestPreparation/EndOfSkillTestPreparation';
import FinalAssessmentPreparation from '@/components/FinalAssessmentPreparation/FinalAssessmentPreparation';
import LevelTestLogOut from '@/components/LevelTest/LevelTestLogOut/LevelTestLogOut';
import { useTranslation } from 'react-i18next';
import { gameActions, gameSelector } from '@/store/reducers/game';
import TutorButton from '@/assets/svg/tutor/tutor_chat.svg';
import TutorMuteButton from '@/assets/svg/tutor/TutorMute.svg';
import { useDispatch } from 'react-redux';
import { tutorActions, tutorSelector } from '@/store/reducers/tutor';
import TutorChatInterface from '@/components/Tutor/TutorChatInterface/TutorChatInterface';
import { TriggeredBy } from '@/types/tutor';

const QuizViewDesktop = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    loadQuizProgress,
    currentQuestion,
    selectedTopic,
    selectedQuiz,
    selectedSkill,
    selectedType,
    gameStatus,
    currentQuestionIndex,
    currentReadQuestionIndex,
    gameQuestions,
    gameType,
    coins,
    currentTime,
    currentCoins,
    shouldDisplayPopUp,
    popUpType,
    popUpProps,
    isMuted,
    allowRetry,
    showPreQuiz,
    onMute,
    onPause,
    onStartQuiz,
    onPractice,
    onDisableRetry,
    onClosePopUp,
    onAknowledge,
    onSwipe,
    toggleCheckAnswer,
    goToNextQuestion,
  } = UseQuiz();

  const {
    searchValue,
    selectedWord,
    cards,
    phrases,
    pictureOfTheDay,
    options,
    question,
    isSearching,
    onInputChange,
    onSearch,
    onClear,
    onValueSearch,
  } = UseDictionary();

  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const { user } = useAppSelector(authSelector);

  const { mute, triggeredBy } = useAppSelector(tutorSelector);

  const { largeWidth, windowWidth } = UseScreenSize();
  const isInEndOfSkillTest =
    selectedTopic?.topicType === TopicTypes.EndOfSkillTest;
  const onAnswer = (userAnswer: string) => {
    toggleCheckAnswer(userAnswer);
  };

  const setClasseByPopType = useCallback(
    (state: TransitionStatus) => {
      return popUpType === PopUpTypes.WordsList
        ? `slide-horizontal-${interfaceLanguage?.direction}-${state}`
        : `fade-${state}`;
    },
    [popUpType]
  );

  const onGoBack = () => {
    return navigate('/dashboard/games');
  };

  const isPractice = gameType === GameTypes.Practice;
  const { isLevelTest, isFinalAssessment } = useAppSelector(gameSelector);
  const isRead = selectedType === SkillTypes.Read;
  const isSpeak = selectedType === SkillTypes.Speak;
  const localLang = UseLocalLang();

  const quizQuestion = (
    <QuizQuestion
      currentQuestion={currentQuestion}
      allowRetry={allowRetry}
      emitOnAnswer={onAnswer}
      emitOnRetry={onDisableRetry}
      emitOnNextQuestion={goToNextQuestion}
      emitOnSwipe={onSwipe}
      emitOnAknowledge={onAknowledge}
    />
  );

  const quizControls = (
    <QuizPageControls
      actual={currentQuestionIndex + 1 ?? 0}
      total={gameQuestions.length}
      onMute={onMute}
      onPause={onPause}
      isMuted={isMuted}
      isPaused={gameStatus === GameStatus.PAUSED}
      loading={loadQuizProgress}
    />
  );

  const popUpComponent = (
    <Transition
      in={Boolean(shouldDisplayPopUp && popUpType && popUpProps)}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={(state) => {
        return (
          <>
            <AppBackdrop
              className={classes(classNames.backdrop, `backdrop-${state}`, {
                [classNames.backdropWhite]:
                  popUpType === PopUpTypes.Focus ||
                  popUpType === PopUpTypes.KeepGoing,
              })}
              emitClose={() => {
                if (popUpProps?.canClickOutside === false) {
                  return;
                }

                if (
                  popUpType !== PopUpTypes.Summary &&
                  popUpType !== PopUpTypes.TimeIsUp
                ) {
                  onClosePopUp();
                  return;
                }
              }}
            />
            <AppModal>
              <QuizPopUp
                {...popUpProps}
                className={classes(classNames.modal, setClasseByPopType(state))}
                type={popUpType}
                tip={
                  commonUtils.getQuestionTip(
                    currentQuestion ?? {},
                    localLang
                  ) ?? ''
                }
              />
            </AppModal>
          </>
        );
      }}
    />
  );

  const levelTestLogoutComponent = (
    <LevelTestLogoutComponent className={classNames.levelTestSignOut} />
  );

  if (loadQuizProgress) {
    return <AppLoader />;
  }

  let content = (
    <div className="flex flex-1 justify-between gap-8 px-11">
      <div className={classes(classNames.quizDesktopContent)}>
        {quizControls}
        {!isFinalAssessment && (
          <div className={classNames.detailsAndIndicators}>
            <QuizDetails
              selectedType={selectedType}
              selectedSkill={selectedSkill}
              selectedTopic={selectedTopic}
              selectedQuiz={selectedQuiz}
              shouldDisplayImage={true}
            />
            {isPractice ? (
              <QuizLanguageToggler />
            ) : (
              !isInEndOfSkillTest && (
                <QuizPageIndicators
                  earnedCoins={coins}
                  timesUp={!loadQuizProgress && currentTime <= 5}
                  coins={currentCoins}
                  timer={currentTime ?? 0}
                  selectedType={selectedType}
                />
              )
            )}
          </div>
        )}
        {quizQuestion}
      </div>
      {popUpComponent}
      {isPractice && largeWidth <= windowWidth && (
        <WordsList emitOnAknowledge={onAknowledge} />
      )}
    </div>
  );

  let showPreparation =
    (!isInEndOfSkillTest && (showPreQuiz || isRead)) ||
    (isInEndOfSkillTest && !showPreQuiz && isRead);

  if (showPreparation) {
    content = (
      <>
        <div className="px-11 py-3 flex-1 overflow-x-hidden overflow-y-auto">
          {isLevelTest && levelTestLogoutComponent}
          <QuizPreparation
            currentQuestion={
              isLevelTest
                ? gameQuestions[currentReadQuestionIndex]
                : currentQuestion
            }
            currentQuestionIndex={
              isLevelTest ? currentReadQuestionIndex : currentQuestionIndex
            }
            gameQuestions={gameQuestions}
            loading={loadQuizProgress}
            selectedQuiz={selectedQuiz}
            selectedSkill={selectedSkill}
            selectedTopic={selectedTopic}
            selectedType={selectedType}
            coins={coins}
            currentCoins={currentCoins}
            currentTime={currentTime}
            isPaused={gameStatus === GameStatus.PAUSED}
            emitOnStartQuiz={onStartQuiz}
            emitGoBack={onGoBack}
            emitOnPractice={onPractice}
            emitPause={onPause}
          />
        </div>
        {popUpComponent}
      </>
    );
  }

  if (showPreQuiz) {
    if (isInEndOfSkillTest) {
      content = (
        <>
          <div className="px-11 py-3 flex-1 overflow-x-hidden overflow-y-auto">
            <EndOfSkillTestPreparation
              selectedTopic={selectedTopic}
              selectedQuiz={selectedQuiz}
              selectedSkill={selectedSkill}
              selectedType={selectedType}
              emitOnStartQuiz={onStartQuiz}
            />
          </div>
          {popUpComponent}
        </>
      );
    } else if (isFinalAssessment && !isRead) {
      content = (
        <>
          <div className="px-11 py-3 flex-1 overflow-x-hidden overflow-y-auto">
            <FinalAssessmentPreparation />
          </div>
          {popUpComponent}
        </>
      );
    }
  }

  if (isLevelTest && !isRead) {
    content = (
      <>
        {isLevelTest && levelTestLogoutComponent}
        {quizQuestion}
        {popUpComponent}
      </>
    );
  }

  const dictionaryComponents = (
    <DictionaryDesktopComponents
      selectedWord={selectedWord}
      cards={cards}
      phrases={phrases}
      question={question}
      pictureOfTheDay={pictureOfTheDay}
      options={options}
      emitBack={onClear}
      onValueSearch={onValueSearch}
    />
  );

  // === TUTOR ====

  const onOpenTutor = () => {
    dispatch(tutorActions.openTutor(TriggeredBy.Manual));
    dispatch(gameActions.toggleGameStatus(GameStatus.SHOW_TUTOR));
  };

  const onCloseTutor = () => {
    dispatch(tutorActions.closeTutor());
    dispatch(gameActions.toggleGameStatus(GameStatus.PLAYING));
    dispatch(gameActions.setMinimizedTutor(true))

    if (
      triggeredBy === TriggeredBy.Focus ||
      triggeredBy === TriggeredBy.KeepGoing ||
      triggeredBy === TriggeredBy.AssistPopup
    )
      goToNextQuestion(true);
  };

  // === TUTOR ====

  return (
    <div
      className={classes(
        classNames.quizDesktop,
        isSpeak && showPreQuiz ? 'flex-1' : 'h-full',
        {
          [classNames.quizDesktopLevelTest]: isLevelTest,
          [classNames.quizDesktopDictionary]: selectedWord,
          [classNames.quizDesktopFinalAssessment]: isFinalAssessment,
        }
      )}
    >
      {!isLevelTest && !isFinalAssessment && (
        // <TheHeader
        //   onSearch={onSearch}
        //   onInputChange={onInputChange}
        //   searchValue={searchValue}
        // />
        <AppBanner className={classNames.appBanner} showProfileInfo />
      )}
      {/* {isSearching ? (
        <AppLoaderCircle className="m-auto" />
      ) : selectedWord ? (
        <div className={classNames.quizDictionaryWrapper}>
          {dictionaryComponents}
        </div>
      ) : (
        content
      )} */}
      {content}
      <div className={classNames.tutorIcon}>
        <img
          src={mute ? TutorMuteButton : TutorButton}
          alt="tutor"
          onClick={onOpenTutor}
        />
      </div>

      <TutorChatInterface
        isFixed={false}
        emitOpen={onOpenTutor}
        emitClose={onCloseTutor}
      />
    </div>
  );
};

export default QuizViewDesktop;
