import { PopUpFocusProps } from '@/types/game';
import classNames from './TutorAssistPopupDesktop.module.scss';
import { useTranslation } from 'react-i18next';
import classes from 'classnames';
import { useMemo, useState } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useAppSelector } from '@/store';
import { interfaceLanguageSelector } from '@/store/reducers/auth';

import TheButton from '@/components/TheButton/TheButton';

import RobotIcon from '@/assets/svg/tutor/Robot_invalid.svg';
import CrossIcon from '@/assets/svg/tutor/assistPopup/cross.svg';
import MuteIcon from '@/assets/svg/tutor/assistPopup/mute.svg';

import { useDispatch } from 'react-redux';
import { tutorActions } from '@/store/reducers/tutor';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { TriggeredBy } from '@/types/tutor';
import GameUtils from '@/utils/gameUtils';

type TutorAssistPopupDesktop = {
  emitClose: () => void;
};

const TutorAssistPopupDesktop = ({ emitClose }: TutorAssistPopupDesktop) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { currentQuestionIndex, gameQuestions, pickedAnswer } =
    useAppSelector(gameSelector);

  const currentQuestion = useMemo(() => {
    return gameQuestions[currentQuestionIndex];
  }, [currentQuestionIndex, gameQuestions]);

  const handleGotIt = () => {
    dispatch(gameActions.goToNextQuestion({}));
    emitClose();
  };

  const handleExplainMore = () => {
    dispatch(tutorActions.closeTutorAssist());
    dispatch(tutorActions.openTutor(TriggeredBy.AssistPopup));
  };

  const handleMute = () => dispatch(tutorActions.openTutorMuteConfirmPopup());

  return (
    <div className={classes('container', classNames.tutorAssist)}>
      <div className={classNames.left}>
        <img src={RobotIcon} alt="robot" />
      </div>

      <div className={classNames.middle}>
        <div className={classNames.incorrectAnswer}>
          <img className={classNames.icon} src={CrossIcon} />
          <p>{pickedAnswer}</p>
        </div>

        <div className={classNames.context}>
          <p>
            {t('tutorCorrectAnswerIs', { answer: currentQuestion?.answer })}
          </p>
          <span>{GameUtils.getTipPerDistractor(currentQuestion,pickedAnswer??"")}</span>
        </div>
      </div>

      <div className={classNames.right}>
        <img src={MuteIcon} alt="mute" onClick={handleMute} />

        <TheButton
          plain
          text={t('tutorGotIt')}
          emitOnClick={() => {
            dispatch(
              tutorActions.setSuggestedTriggered(TriggeredBy.AssistPopup)
            );
            handleGotIt();
          }}
        />

        <TheButton
          showArrow
          shouldRecolor={false}
          text={t('tutorExplainMore')}
          emitOnClick={handleExplainMore}
          className={classNames.theButton}
        />
      </div>
    </div>
  );
};

export default TutorAssistPopupDesktop;
