import { useCallback, useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import config from '@/config';
import { Configuration, NewSessionData, StreamingAvatarApi } from '@heygen/streaming-avatar';
import { useTranslation } from 'react-i18next';
import classNames from './AvatarStream.module.scss';
import classes from 'classnames';

interface AvatarStream {
  onReady: () => void;
  onStartTalking: () => void;
  onStopTalking: () => void;
}

const AvatarStream = forwardRef(({ onReady, onStartTalking, onStopTalking }: AvatarStream, ref) => {
  const { t } = useTranslation();
  const mediaStream = useRef<HTMLVideoElement>(null);
  const avatar = useRef<StreamingAvatarApi | null>(null);
  const data = useRef<NewSessionData>();
  const canvasRef = useRef(null);
  const [initialized, setInitialized] = useState(false); 
  const [stream, setStream] = useState<MediaStream>();
  const [accessToken, setAccessToken] = useState<any>(null);

  //const apiKey = config.apiAvatarStrimingToken;
  //const apiKey = "OTEyZGNkMjk1ZDg1NDNhZGExZmJhYjU4YWUyYTBlMDgtMTcyMDk1MjUyMQ=="
  const apiKey = "YzY0MzA2Njc3OTBhNGY1YTlmN2NlZDFmMzRhYzk2NDMtMTcyMjQ0MzI1Mg==" // anton.estherkin@gmail.com

  const fetchAccessToken = async () => {
        try {
            const response = await fetch('https://api.heygen.com/v1/streaming.create_token', {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey
                }
            });
            const result = await response.json();
            return result.data.token;
        } 
        catch (error) {
            console.error('Error fetching streaming avatar access token:', error);
            return '';
        }
  };

  const closeAllsSessions = async () => {
    try {
        const headers = {
            'x-api-key': apiKey
        };

        const response = await fetch('https://api.heygen.com/v1/streaming.list', { method: 'GET', headers: headers });
        const result = await response.json();

        if (result.data.sessions) {
            for (let i = 0; i < result.data.sessions.length; i++) {
                try {
                    const {api_key_type, session_id} = result.data.sessions[i];
                    if (api_key_type === "trial") {
                        await fetch('https://api.heygen.com/v1/streaming.stop', { 
                            method: 'POST', 
                            headers: headers, 
                            body: JSON.stringify({
                                session_id: session_id,
                            }),
                        });
                    }
                }
                catch (error) {
                    console.error('Error close session:', error);
                    return '';
                }
            }
        }

        return result.data.token;
    } 
    catch (error) {
        console.error('Error get list sessions:', error);
        return '';
    }
  };

  const updateToken = async () => {
        //await closeAllsSessions();
        const newToken = await fetchAccessToken();
        avatar.current = new StreamingAvatarApi(
            new Configuration({ accessToken: newToken })
        );

        const startTalkCallback = (e: any) => {
            onStartTalking();
        };

        const stopTalkCallback = (e: any) => {
            onStopTalking();
        };

        console.log('Adding event handlers:', avatar.current);
        avatar.current.addEventHandler("avatar_start_talking", startTalkCallback);
        avatar.current.addEventHandler("avatar_stop_talking", stopTalkCallback);

        setAccessToken(newToken);
  };

  const startStreaming = async () => {
    await updateToken();

    try {
        if (avatar.current) {
            const res = await avatar.current.createStartAvatar({
              newSessionRequest: {
                quality: "low",
                avatarName: 'Tyler-incasualsuit-20220721', //Tyler-incasualsuit-20220721  Kristin_public_2_20240108
                voice: { voiceId: '433c48a6c8944d89b3b76d2ddcc7176a' } //433c48a6c8944d89b3b76d2ddcc7176a
              }
            });
            data.current = res;
            setStream(avatar.current.mediaStream);
        }
    } catch (error) {
        console.error('Error starting avatar session:', error);
    }
  };

  const stopStreaming = async () => {
    try {
        if (avatar.current && data.current?.sessionId) {
            await avatar.current.stopAvatar({ stopSessionRequest: { sessionId: data.current?.sessionId } });
            avatar.current = null;
        }
    }
    catch {}
  };

  const restart = useCallback(async () => {
    await stopStreaming();
    startStreaming();
  }, [stopStreaming, startStreaming]);

  const handleSpeak = useCallback(async (text: string) => {
    if (avatar.current && data.current?.sessionId) {
        await avatar.current.speak({ taskRequest: { text: text, sessionId: data.current?.sessionId } });
    }
  }, []);

  useImperativeHandle(ref, () => {
    return {
        getAccessToken: () => {
            return accessToken;
        },
        speak: handleSpeak,
    };
  }, [accessToken, handleSpeak]);

  useEffect(() => {
    startStreaming();

    return () => {
        stopStreaming();
    };
  }, []);

  useEffect(() => {
    if (initialized) {
        onReady();
    }
  }, [initialized, onReady]);

  useEffect(() => {
    let isActive = true;
    let timer = setInterval(() => {
        if (isActive) {
            handleSpeak("!!!");
        }
    }, 1000 * 60 * 9);

    return () => {
        isActive = false;
        clearInterval(timer);
    };
  }, [accessToken, handleSpeak]);

  useEffect(() => {
    let isActive = true;
    let isPlaying = false;
    let timeupdateTimeout: any;

    if (stream && mediaStream.current) {
        mediaStream.current.onloadedmetadata = () => {
            mediaStream.current!.play();
            setTimeout(() => {
                if(isActive && mediaStream.current) {
                    setInitialized(true);
                }
            }, 100);
        };

        mediaStream.current.ontimeupdate = () => {
            if (isPlaying) {
                if (timeupdateTimeout) {
                    clearTimeout(timeupdateTimeout);
                }
                timeupdateTimeout = setTimeout(async () => {
                    if (isActive) {
                        await stopStreaming();
                        startStreaming();
                    }
                }, 1000 * 5);
            }
        };

        mediaStream.current.onplay = () => {
            if (isActive) {
                isPlaying = true;
                const video:any = mediaStream.current;
                const canvas:any = canvasRef.current;
                const tempCanvas = window.document.createElement('canvas');
                const ctx:any = canvas.getContext("2d");
                const tempCtx:any = canvas.getContext("2d");
                const width = video.videoWidth;
                const height = video.videoHeight;
                canvas.width = width;
                canvas.height = height;
                tempCanvas.width = width;
                tempCanvas.height = height;

                const drawImage = () => {
                    if (isActive) {
                        tempCtx.drawImage(video, 0, 0, width, height);

                        let frame = tempCtx.getImageData(0, 0, width, height);
                        let l = frame.data.length / 4;
                    
                        for (let i = 0; i < l; i++) {
                            let r = frame.data[i * 4 + 0];
                            let g = frame.data[i * 4 + 1];
                            let b = frame.data[i * 4 + 2];
                            if (g > 150 && r < 100 && b < 100)
                                frame.data[i * 4 + 3] = 0;
                        }
                        ctx.putImageData(frame, 0, 0);
                        
                        requestAnimationFrame(drawImage);
                    }
                };

                drawImage();
            }
        };

        mediaStream.current.srcObject = stream;
    }

    return () => {
        isActive = false;
        if (timeupdateTimeout) {
            clearTimeout(timeupdateTimeout);
        }
    };
  }, [mediaStream, stream]);
  

  return <div className={classNames.media_player}>
    <video playsInline autoPlay ref={mediaStream}></video>
    <canvas ref={canvasRef} className={classNames.canvas} />
    <button onClick={restart} className={classNames.restart}>restart</button>
  </div>;
});

export default AvatarStream;
