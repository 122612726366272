import React, { useEffect, useMemo, useRef, useState, RefObject } from 'react';
import classNames from './TutorChatBody.module.scss';
import classes from 'classnames';
import ScrolDown from '@/assets/svg/tutor/chat/scrolDown.svg';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import {
  fetchGPTResponse,
  fetchTutorActivity,
  fetchTutorProcess,
  tutorActions,
  tutorSelector,
} from '@/store/reducers/tutor';
import { TutorActivityType, TutorElement } from '@/types/tutor';
import UseScreenSize from '@/hooks/UseScreenSize';
import { TriggeredBy, TutorSender } from '@/types/tutor';
import TutorGroupMessages from './TutorGroupMessages/TutorGroupMessages';
import AppBackdrop from '@/components/AppBackdrop';
import { Transition } from 'react-transition-group';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import AppModal from '@/components/AppModal';
import useTutor from '@/hooks/useTutor';
import ChatInput from './ChatInput/ChatInput';

// Define types for the refs. Assuming they are for HTML elements.
interface ScrollToBottomParams {
  isDashboard: boolean;
  chatBodyRef: RefObject<HTMLElement>;
  messagesEndRef: RefObject<HTMLElement>;
}

type TutorChatBody = {
  isDashboard?: boolean;
  pauseRecording?: boolean;
  speak?: (text: string) => void;
  className?: string;
};

const SCROLL_TIME_OUT = 100;

const TutorChatBody: React.FC<TutorChatBody> = ({ isDashboard = false, pauseRecording = false, speak, className }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { isMobile } = UseScreenSize();

  const {
    setUserChoice,
    isShowOptions,
    setUserMessage,
    updateLastLoadingMessage,
  } = useTutor();

  const {
    messages,
    userChoice,
    elements,
    timeStart,
    triggeredBy,
    showTutorShiftPopup,
  } = useAppSelector(tutorSelector);

  const {
    selectedType,
    currentQuestionIndex,
    gameQuestions,
    selectedQuiz,
    selectedTopic,
  } = useAppSelector(gameSelector);

  const promptTopic = useMemo(() => {
    return `${selectedType}; ${selectedQuiz?.name}`;
  }, [selectedQuiz, selectedType]);

  const [showScrollDown, setShowScrollDown] = useState(false);

  const chatBodyRef = useRef<HTMLDivElement | null>(null);

  // Function to handle scrolling down
  const handleScrollDown = () => {
    if (chatBodyRef.current) {
      // Scroll to the bottom of the chat body
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  // Effect to toggle scroll down button visibility
  useEffect(() => {
    // Get the chat body element
    const chatBody = chatBodyRef.current;

    // Function to check if the chat body is scrolled to the bottom
    const checkScroll = () => {
      // If the chat body is not found, return
      if (!chatBody) return;

      // Check if the chat body is scrolled to the bottom
      const isScrolledToBottom =
        chatBody.scrollHeight - chatBody.scrollTop <= chatBody.clientHeight;

      // Set the state to show the scroll down button based on the scroll position
      setShowScrollDown(!isScrolledToBottom);
    };

    // Check the scroll position on mount
    checkScroll();

    // Add a scroll event listener to the chat body
    chatBody?.addEventListener('scroll', checkScroll);

    // Remove the scroll event listener on unmount
    return () => {
      chatBody?.removeEventListener('scroll', checkScroll);
    };
  }, [messages]);

  // Fetch tutor activity
  useEffect(() => {
    // Fetch tutor activity
    // dispatch(fetchTutorActivity({ activity_type: TutorActivityType.tutor }));

    // Push the trigger history
    // dispatch(
    //   tutorActions.pushTriggresHistory({
    //     quiz_id: selectedQuiz?.id,
    //     topic_id: selectedTopic?.id,
    //     question_num: gameQuestions[currentQuestionIndex]?.order,
    //     question_id: gameQuestions[currentQuestionIndex]?.questionId,
    //     triggeredBy,
    //   })
    // );

    // Close the tutor if the user choice is set
    return () => {
      dispatch(tutorActions.closeTutor());
    };
  }, []);

  // Fetch tutor process
  useEffect(() => {
    dispatch(
      fetchGPTResponse({
        userQuery: '',
        topic: promptTopic,
      })
    );
    // dispatch(fetchTutorProcess({}));
  }, []);

  // Ref for auto-scrolling to the latest message
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  // Scroll to the bottom of the chat interface
  const scrollToBottom = ({
    isDashboard,
    chatBodyRef,
    messagesEndRef,
  }: ScrollToBottomParams): void => {
    if (isDashboard) {
      // Dashboard-specific scrolling logic
      if (chatBodyRef.current) {
        chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
      }
    } else {
      // Standard chat view scrolling logic
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Usage within a functional component
  // Assuming 'messages' is an array of some type, we'll use any[] for demonstration.
  // Replace 'any[]' with the appropriate type for your messages.
  useEffect(() => {
    // Set a timeout to ensure scrolling occurs after messages are rendered
    const timer = setTimeout(() => {
      scrollToBottom({ isDashboard, chatBodyRef, messagesEndRef });
    }, SCROLL_TIME_OUT);

    // Cleanup function to clear the timeout when the component unmounts or messages update
    return () => clearTimeout(timer);
  }, [isDashboard, messages]); // Dependencies array includes isDashboard to handle UI context changes

  const [userQuery, setUserQuery] = useState('');

  const handleSubmit = async (
    event?: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    if (event) {
      event.preventDefault(); // Prevent default form submission behavior
    }

    if (userQuery) {
      setUserMessage(userQuery);
  
      dispatch(
        fetchGPTResponse({
          userQuery: userQuery,
          topic: promptTopic,
        })
      );
  
      setUserQuery(''); // Clear the user query input field
    }
  };

  useEffect(() => {
    // dispatch(fetchGPTResponse(userQuery));
    // const lastMessageGroup = messages[messages.length - 1];
    // if (
    //   lastMessageGroup?.sender === TutorSender.tutor &&
    //   lastMessageGroup?.message[lastMessageGroup.message.length - 1].loading
    // ) {
    //   const interval = setInterval(() => {
    //     // Assuming this action updates the message state correctly, including setting `loading` to false.
    //     updateLastLoadingMessage('This is dummy response');
    //   }, 1000);
    //   // Cleanup function to clear the interval
    //   return () => clearInterval(interval);
    // }
  }, [messages]);

  return (
    <div
      className={classes(classNames.chatBody, className, {
        [classNames.isDashboard]: isDashboard,
        [classNames.isDashMobile]: isMobile && isDashboard,
      })}
      ref={chatBodyRef}
    >
      <Transition
        in={showTutorShiftPopup}
        timeout={150}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <>
            <AppBackdrop className={classes(`backdrop-${state}`)} />
            <AppModal>
              <div className={classNames.shiftModal}>
                <AppLoaderCircle />
                <p>
                  {t('tutorShifting', { quizName: selectedQuiz?.name || '' })}
                </p>
              </div>
            </AppModal>
          </>
        )}
      </Transition>

      {/* {showScrollDown && (
        <div className={classNames.scrollDownButton} onClick={handleScrollDown}>
          <img src={ScrolDown} alt="Scroll down" />
        </div>
      )} */}

      <div>
        <div className={classNames.info}>
          <div className={classes(classNames[`skill-${selectedType ?? ''}`])}>
            <p>
              {selectedType
                ? selectedType.charAt(0).toUpperCase() + selectedType.slice(1)
                : ''}
            </p>
          </div>

          <p>{t('tutorStartedAt', { timeStart })}</p>
        </div>
        {messages.map((message, index) => (
          <TutorGroupMessages key={index} index={index} payload={message} speak={speak} />
        ))}

        {/* Dummy div for auto-scrolling */}
        <div ref={messagesEndRef} />
      </div>

      <ChatInput {...{ userQuery, setUserQuery, handleSubmit }} pauseRecording={pauseRecording} />
    </div>
  );
};

export default React.memo(TutorChatBody);
