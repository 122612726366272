import { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from './TutorAvatar.module.scss';
import classes from 'classnames';
import TutorIcon from '@/assets/svg/tutor/tutorDashboard.svg';
import { useAppDispatch, useAppSelector } from '@/store';
import TutorChatBody from '../TutorDialogueManager/TutorChatBody';
import { tutorActions, tutorSelector } from '@/store/reducers/tutor';
import { TriggeredBy } from '@/types/tutor';
import AvatarStream from '../../AvatarStream';

const TutorAvatar = () => {
  const [ isAvatarReady, setIsAvatarReady ] = useState<boolean>(false);
  const [ pauseRecording, setPauseRecording ] = useState<boolean>(false);
  const avatarRef = useRef<any>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(tutorActions.openTutor(TriggeredBy.TutorDemo));

    return () => {
      dispatch(tutorActions.closeTutor());
    };
  }, []);

  const onAvatarReadyHandle = useCallback(() => {
    setIsAvatarReady(true);
  }, []);

  const onSpeakHandle = useCallback((text: string) => {
    if (avatarRef.current) {
      avatarRef.current.speak(text);
    }
  }, []);

  const onAvatarStartTalkingHandle = useCallback(() => {
    setPauseRecording(true);
  }, []);

  const onAvatarStopTalkingHandle = useCallback(() => {
    setPauseRecording(false);
  }, []);

  const content = (<>
    <div className={classNames.chat}>
      {isAvatarReady && <TutorChatBody speak={onSpeakHandle} pauseRecording={pauseRecording} className={classNames.tutor_chat_body} />}
    </div>
    <div className={classNames.avatar}>
      {true && <AvatarStream onReady={onAvatarReadyHandle} onStartTalking={onAvatarStartTalkingHandle} onStopTalking={onAvatarStopTalkingHandle} ref={avatarRef} />}
    </div>
  </>
  );

  return content;
};

export default TutorAvatar;
