import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';

import styles from './TutorGroupMessages.module.scss';
//import TutorImage from '@/assets/svg/tutor/chat/tutor.svg';
import TutorImage from '@/assets/svg/tutor/chat/teachersAvatarM.png';
//import TutorImage from '@/assets/svg/tutor/chat/teachersAvatarF.png';
import TutorPencil from '@/assets/svg/tutor/pencilIcon.svg';
import LikeIcon from '@/assets/svg/tutor/chat/tutor_like.svg';
import DisLikeIcon from '@/assets/svg/tutor/chat/tutor_dislike.svg';
import ExplanationIcon from '@/assets/svg/tutor/chat/explanation.svg';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/store';
import TutorMessage from './TutorMessage/TutorMessage';
import UseScreenSize from '@/hooks/UseScreenSize';
import {
  TutorGroupMessages as TutorGroupMessagesTypes,
  TutorSender,
} from '@/types/tutor';
import { tutorActions } from '@/store/reducers/tutor';

// Component's props definition
export interface TutorGroupMessagesProps {
  index: number;
  payload: TutorGroupMessagesTypes;
  speak?: (text: string) => void;
  onResponseOptionsSelect?: (option: string) => void;
  onMessageSeen?: (groupIndex: number, messageIndex: number) => void;
  onExplanationOpen?: (groupIndex: number, messageIndex: number) => void;
}

const TutorGroupMessages: React.FC<TutorGroupMessagesProps> = ({
  index,
  payload,
  speak,
  onResponseOptionsSelect,
  onMessageSeen,
  onExplanationOpen,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { isMobile, isDesktop } = UseScreenSize();

  const { sender, message, includeThumbs, timeStamp } = payload;

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  // Determining if the message is from user or tutor
  const isUser = sender === TutorSender.user;
  const isTutor = sender === TutorSender.tutor;

  const isExercice = !!message[message.length - 1].exercise;

  const icon = isExercice ? TutorPencil : TutorImage;

  const generateClassNames = (style: string) => {
    const autoMargin = {
      [styles.marginLeft]: isUser,
      [styles.marginRight]: isTutor,
    };
    return classNames(styles[style], autoMargin);
  };

  const appendTutorExplanation = (index: number, explanation?: string) => {
    if (explanation) {
      dispatch(tutorActions.appendTutorExplanation({ explanation, index }));
    }
  };

  useEffect(() => {
    if (message[currentMessageIndex]?.hasSeen) {
      setCurrentMessageIndex((current) => current + 1);
    }
  }, [message, currentMessageIndex]);

  return (
    <div key={index} className={generateClassNames('bubbleContainer')}>
      <div className={generateClassNames('messageContainer')}>
        <div
          className={classNames({
            [styles.displayNone]: isUser,
            [styles.icon]: isTutor,
            [styles.iconDesktop]: isTutor && isDesktop,
            [styles.pencil]: isExercice,
            [styles.pencilDesktop]: isTutor && isExercice,
          })}
        >
          <img src={icon} alt="tutor icon" />
        </div>

        <div className={classNames(styles.messageGroup)}>
          {message
            .slice(0, currentMessageIndex + 1)
            .map((payload, messageIndex) => (
              <div key={messageIndex} className={classNames("flex","items-center", "gap-2", {[styles.isExplanation]: payload.explanation && messageIndex === message.length - 1})}>
                {payload.explanation && messageIndex === message.length - 1 && (
                  <button
                    onClick={() => {
                      //appendTutorExplanation(index, payload.explanation);
                      if (payload.explanation && onExplanationOpen) {
                        onExplanationOpen(index, messageIndex);
                      }
                    }}
                  >
                    <img src={ExplanationIcon} className={styles.lightExplanation} />
                  </button>
                )}
                <TutorMessage
                  messageIndex={messageIndex}
                  groupIndex={index}
                  payload={payload}
                  sender={sender}
                  speak={speak}
                  onMessageSeen={onMessageSeen}
                />
              </div>
            ))}
        </div>
      </div>
      <div
        className={classNames(styles.info, {
          [styles.displayNone]: timeStamp,
          [styles.infoDesktop]: isDesktop,
        })}
      >
        <p
          className={classNames({
            [styles.marginLeft]: isUser,
            [styles.marginRight]: isTutor,
          })}
        >
          {isTutor ? t('tutorPrivate') : t('tutorUser')} • {timeStamp}
        </p>
        {includeThumbs && isTutor && (
          <div className={styles.like}>
            <img
              src={DisLikeIcon}
              alt="dislike icon"
              onClick={() => {
                dispatch(tutorActions.setUserFeedback('n'));
              }}
            />
            <img
              src={LikeIcon}
              alt="like icon"
              onClick={() => {
                dispatch(tutorActions.setUserFeedback('y'));
              }}
            />
          </div>
        )}
      </div>
      {
        payload && 
        payload.message && payload.message.length > 0 && 
        payload.message[payload.message.length - 1].hasSeen && 
        payload.message[payload.message.length - 1].responseOptions && 
        <div className={styles.response_option}>
          {payload.message[payload.message.length - 1].responseOptions?.map((option, optionIndex) => {
            return <button 
              key={`responseOption--${optionIndex}`} 
              onClick={() => {
                if (onResponseOptionsSelect) { 
                  onResponseOptionsSelect(option); 
                }
              }}
            >{option}</button>
          })}
        </div>
      }
    </div>
  );
};

export default React.memo(TutorGroupMessages);
