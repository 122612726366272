import { Question } from './question';
import { SkillTypes } from './skill';

export enum TutorSender {
  tutor = 1,
  user = 2,
}

export enum TriggeredBy {
  Manual = 'manual',
  Focus = 'focusScreen',
  KeepGoing = 'keepGoing',
  AssistPopup = 'assistPopup',
  Summary = 'summary',
  Tooltip = 'tooltip',
  HomePage = 'homePage',
  TutorDemo = 'demo',
}
export enum TutorActivityType {
  path = 'path',
  tutor = 'tutor',
}

export enum FilterTypes {
  AscendingAZ = 0,
  DescendingZA = 1,
  NewestFirst = 2,
  OldestFirst = 3,
  Relevance = 4,
  ByStarRating = 5,
}

export enum MessageTypes {
  Response = 'response',
  Exercise = 'exercise',
  ExerciseAnswer = 'exerciseAnswer',
  Feedback = 'feedback',
  Shift = 'shift',
  Loading = 'loading',
  Explanation = 'explanation',
}

export type TutorStatus = 'show' | 'hidden';

export type QuizSubType =
  | 'Nouns'
  | 'Verbs'
  | 'Adjectives'
  | 'Adverbs'
  | 'Phrases'
  | 'Expressions';

export type LastGroupMessageInfo = {
  lastGroupMessageIndex: number;
  lastGroupMessageCopy: TutorGroupMessages;
};

export type TutorMessage = {
  title?: string | null;
  text?: string | Question;
  speakText?: string;
  hasSeen?: boolean;
  loading?: boolean;
  exercise?: Question | null;
  answeredCorrectly?: boolean;
  pickedAnswer?: string;
  type: MessageTypes;
  crossedOut?: boolean;
  explanation?: string;
  hasCorrectedVersion?: boolean;
  responseOptions?: string[];
};

export type TutorGroupMessages = {
  sender: TutorSender;
  message: Array<TutorMessage>;
  timeStamp?: string;
  includeThumbs?: boolean;
};

// export type tutorResponse = {
//   session?: object;
//   message: TutorMessage;
//   isExercice?: boolean;
//   timeStamp?: string;
//   elements?: Array<object>;
//   include_thumbs?: boolean;
//   hasSeen?: boolean;
// };
export type GPTRequest = {
  topic: string;
  userQuery: string;
};

export type GPTResponse = {
  messages_display: GPTMessageType[];
  history: GPTMessageType[];
  corrections: {
    corrected_content: string;
    original_content: string;
    explanation: string;
  };
};

export type GPTMessageType = {
  role: string;
  content: string;
};

export type TutorElement = {
  id?: number;
  type?: string;
  name: string;
  value: {
    text?: string;
    question?: Question;
  };
  // value: string | TutorElement[]; // assuming value is an array of TutorElement when it's not a string
  include_thumbs?: boolean;
  on_true?: TutorElement[];
  on_false?: TutorElement[];
  wasClear?: {
    true: TutorElement[];
    false: TutorElement[];
  };
};

export type TutorFeedback = 'y' | 'n' | 'noResponse';

export type TutorQuestionResponse = {
  id: number;
  isCorrect: boolean;
  answer: string;
  responseTime: number;
  wrongAnswer: string;
  distractorLevel: number;
  hint_used: string | null;
};

export type UserChoice = {
  content_id: number;
  feedback: string | null;
  question_respons: TutorQuestionResponse | null;
};

export type DashboardState = {
  isLoading: boolean;
  error: string | null;
  data: DashboardEventProps | null;
};

export type DashboardEventProps = {
  quizzes_taken: Card[];
  quizzes_not_taken: Card[];
};

export type Card = {
  skill: SkillTypes;
  topic_name: QuizSubType;
  quiz_name: string;
  description: string;
  timestamp_last_time_played: string;
  star_rating: string;
  is_new: boolean;
  quiz_id: string;
  topic_id: string;
};

export type TriggersHistoryTypes = {
  quiz_id: string;
  topic_id: string;
  question_num: number;
  question_id: number;
  triggeredBy: TriggeredBy;
};

export type TutorState = {
  showAssistPopup: boolean;
  showTutorMuteConfirmPopup: boolean;
  showTutorExitConfirmPopup: boolean;
  showTutorShiftPopup: boolean;
  showTutorMute: boolean;
  mute: boolean;
  showTutor: boolean;
  isTutorNeeded: boolean;
  triggeredBy: TriggeredBy | null;
  userChoice: UserChoice | null;
  messages: Array<TutorGroupMessages>;
  elements: Array<TutorElement>;
  activitySessionId: string | null;
  timeStart: string | null;
  loading: boolean;
  error: string | null;
  dashBoard: DashboardState;
  didInitiated: boolean;
  didMinimized: boolean;
  didSelfOpen: boolean;
  tutorSessionId: string | null;
  triggersHistory: Array<TriggersHistoryTypes>;
  question_response: TutorQuestionResponse | null;
  user_feedback: TutorFeedback | null;
  suggested_triggered: TriggeredBy | null;
  gptHistory: GPTMessageType[];
};
