import React, { useEffect } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';

import TutorAvatar from '@/components/Tutor/TutorAvatar';
import { useAppDispatch } from '@/store';
import { fetchDashboardTutor } from '@/store/reducers/tutor';

const TutorDemo = () => {
  const { isMobile } = UseScreenSize();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchDashboardTutor());
  }, []);

  return <TutorAvatar />;
};

export default React.memo(TutorDemo);
