import React, { useState, useEffect, useRef, useCallback } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import SpeechToTextButton from '../../../../SpeechToTextButton';
import styles from './ChatInput.module.scss';

const ChatInput: React.FC<{
  userQuery: string;
  pauseRecording?: boolean;
  setUserQuery: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit: (event?: React.FormEvent<HTMLFormElement>) => void;
}> = ({ userQuery, setUserQuery, handleSubmit, pauseRecording = false }) => {
  const speechToTextButtonRef = useRef<any>(null);
  const [isInputActive, setIsInputActive] = useState(false);
  // Define the ref with the correct type
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { i18n, t } = useTranslation();
  const isRtl = i18n.language === 'he';
  // const isRtl = i18n.dir() === 'rtl';
  // const isRtl = useSelector((state: RootState) => state.auth.rtl);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      // Programmatically submit the form
      e.currentTarget.form && e.currentTarget.form.requestSubmit();
    }
  };

  useEffect(() => {
    // Safely access the properties of the ref after checking it's not null
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = ''; // Reset height
      textarea.style.height = `${Math.min(textarea.scrollHeight, 6 * 20)}px`; // Adjust height based on the scrollHeight
    }
  }, [userQuery]);

  const onSubmit = useCallback((event?: React.FormEvent<HTMLFormElement>) => {
    if (speechToTextButtonRef.current) {
      speechToTextButtonRef.current.reset();
    }
    handleSubmit(event);
  }, [handleSubmit, userQuery]);

  const inputPlaceHolder = isRtl
    ? 'הקלד את השאלה שלך כאן...'
    : t('enterQuestion');

    const onTextRecordingHandle = useCallback((text: string) => {
      setUserQuery(text);
    }, []);
  
    const onStartRecordingHandle = useCallback(() => {

    }, []);
  
    const onEndRecordingHandle = useCallback(() => {
      onSubmit();
    }, [onSubmit]);

    useEffect(() => {
      if (speechToTextButtonRef.current) {
        speechToTextButtonRef.current.pause(pauseRecording);
      }
    }, [pauseRecording]);

  return (
    <div className={styles.wrapper}>
      <div
        className={classnames(styles['message-input'], {
          active: isInputActive,
          rtl: isRtl,
        })}
      >
        <form className={styles['message-form']} onSubmit={onSubmit}>
          <textarea
            ref={textareaRef}
            value={userQuery}
            onChange={(e) => setUserQuery(e.target.value)}
            onKeyDown={handleKeyDown} // Handle the 'Enter' key for submission
            placeholder={inputPlaceHolder}
            onFocus={() => setIsInputActive(true)}
            onBlur={() => setIsInputActive(false)}
            rows={1} // Start with 1 row
            className={classnames({ rtlTextArea: isRtl })}
          />
          <SpeechToTextButton onText={onTextRecordingHandle} onStart={onStartRecordingHandle} onEnd={onEndRecordingHandle} ref={speechToTextButtonRef} className={styles.stt} />
        </form>
        <button className={styles['send-message']} onClick={() => {onSubmit();}}>
          <svg viewBox='0 0 24 25'>
            <g style={{fill: 'currentColor', stroke: 'none'}} >
            <path d="M22.8571 11.0428L2.39996 0.757128C1.37138 0.299985 0.228527 1.21427 0.68567 2.24284L3.54281 9.89999L16.5714 12.0714L3.54281 14.2428L0.68567 21.9C0.342812 22.9286 1.37138 23.8428 2.39996 23.2714L22.8571 12.9857C23.6571 12.6428 23.6571 11.5 22.8571 11.0428Z"/>
            </g>
          </svg>
        </button>
      </div>

      <p className={classnames(styles['input-description'], { rtl: isRtl })}>
        {t('PoweredBy')}{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://insightsui.morfix.com/tou-morfix-insights"
        >
          {t('MelingoAI')}
        </a>
      </p>
    </div>
  );
};

export default ChatInput;
